var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default function init(api) {
    return {
        checkouts: {
            fetchCheckout: (checkoutId, attempts) => __awaiter(this, void 0, void 0, function* () {
                if ((checkoutId || '').toLowerCase().trim() === 'demo') {
                    return {
                        id: 'demo',
                        currency: 'EUR',
                        amount: 123.45,
                        status: 'demo',
                        merchant_code: 'demo',
                        merchant_name: 'demo',
                        merchant_country: 'demo',
                        purpose: 'CHECKOUT',
                    };
                }
                return api.endpoint(`checkouts/${checkoutId}${attempts ? `?attempts=${attempts}` : ''}`, { method: 'GET' });
            }),
            fetchFeesByCheckout: (checkoutId, params) => {
                return api.endpoint(`checkouts/${checkoutId}/fee-calculation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(params),
                });
            },
        },
        calculateGbIban: (options) => {
            return api.endpoint('gb-iban', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(options),
            });
        },
    };
}
